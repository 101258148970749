.form-group {
  margin-bottom: 1rem;
}

.p-datepicker {
  border: unset;
}

.modal-header .btn-close {
  background-image: none;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 0;

  &:focus {
    box-shadow: none;
  }
}

.date-picker-without-label .p-calendar .p-inputtext {
  padding: 0.429rem !important;
}

.date-picker-custom {
  padding: 0rem;
}

.date-picker-custom .p-calendar {
  width: 100%;
}

.date-picker-custom .p-calendar .p-inputtext {
  padding: .375rem .75rem;
  border: none;
  border-width: 0px;
  border-radius: var(--bs-border-radius);
  height: 100%;
  cursor: pointer;
}

.date-picker-custom .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus,
.date-picker-custom .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus,
.date-picker-custom .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus,
.date-picker-custom .p-calendar:not(.p-calendar-disabled).p-focus>.p-inputtext,
.date-picker-custom .p-calendar .p-inputtext:focus {
  box-shadow: none !important;
}

.date-picker-custom .p-calendar .date-picker-icon-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem;
  color: #d5d5d5;
  cursor: pointer;
}

.date-picker-custom .p-calendar .date-picker-clear-icon-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: red;
}

.date-picker-custom.form-control.is-invalid {
  background-image: none;
  padding-right: unset;
}

.form-datatable .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr th,
.form-datatable .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td {
  padding: 0.75rem 0.25rem;
}

.datatables-custom .p-datatable-wrapper,
.datatables-custom .p-datatable.p-component {
  z-index: 0 !important;
}

.datatables-custom .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr th.p-sortable-column:not(.block-sort-column):hover {
  background-color: #e0e0e0;
  color: #333333;
}

.datatables-custom .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr th.p-sortable-column.p-highlight {
  color: #ffffff !important;
  background-color: #672D91 !important;
}

.datatables-custom .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td.last-cell-border {
  border-bottom: 1px solid #dee2e6;
}


.datatables-custom .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr th.block-sort-column {
  cursor: auto !important;
  user-select: unset !important;
}

// Calendar


.datepicker-style-custom .p-datepicker-header {
  font-size: 14px;
}

.datepicker-style-custom .p-datepicker-calendar-container .p-datepicker-calendar {
  font-size: 12px;
  margin: 0rem;
}

.datepicker-style-custom .p-datepicker-calendar-container .p-datepicker-calendar tr th,
.datepicker-style-custom .p-datepicker-calendar-container .p-datepicker-calendar tr td {
  padding: 0.5em;
  text-align: center;
}

.datepicker-style-custom .p-datepicker-calendar-container .p-datepicker-calendar tr span,
.datepicker-style-custom .p-datepicker-calendar-container .p-datepicker-calendar tr td span {
  height: 2rem;
  width: 2rem;
}

.datepicker-style-custom .p-datepicker-inline {
  padding-top: 0;
  padding-bottom: 0;
}

// Button

.btn-icon {
  background-color: transparent;
  border: none;
  padding: 0;
}

.btn-icon:disabled {
  opacity: 0.65;
  pointer-events: none;
}

.btn-icon:active,
.btn-icon:hover {
  -webkit-filter: brightness(0.9);
  filter: brightness(0.9);
}

.btn-icon-delete {
  color: red;
}

// Date Picker

.dropdown-label-group .date-picker-custom ~ label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
  padding-top: calc(var(--input-label-padding-y) / 3);
  padding-bottom: calc(var(--input-label-padding-y) / 3);
  font-size: 12px;
  color: #777;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  pointer-events: none;
}

.dropdown-label-group .date-picker-custom .p-inputtext {
  padding-top: calc(var(--input-label-padding-y) + var(--input-label-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-label-padding-y) / 3);
  padding-left: var(--input-label-padding-y);
  padding-right: var(--input-label-padding-y);
  background: transparent;
}

.dropdown-label-group .date-picker-custom .p-calendar:not(.p-calendar-disabled).p-focus>.p-inputtext,
.dropdown-label-group .date-picker-custom .p-inputtext:active,
.dropdown-label-group .date-picker-custom .p-inputtext:focus {
  box-shadow: unset !important;
}

.dropdown-label-group .date-picker-custom .p-calendar-clear-icon {
  margin-top: 0rem;
  position: unset;
  right: unset;
  top: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-label-group.date-picker-disabled {
  opacity: 0.65;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: default;
}

.dropdown-label-group.date-picker-disabled .date-picker-custom .date-picker-icon-custom,
.dropdown-label-group.date-picker-disabled .date-picker-custom .p-inputtext {
  cursor: default;
}

