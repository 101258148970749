/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import '../public/assets/fonts/fonts';
@import '../public/assets/scss/helper';
@import '../public/assets/scss/variables';
@import '../public/assets/scss/custom';
@import '../public/assets/scss/ng-select';

$primary: #3498db;
$success: #036BB3;
$text: #4A4A4A;
$error: #ff6666;
$warning: #ffba5a;
$background: #edf0f5;
$white: #fafbfc;

html {
  //-moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;

  * {
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    outline: none !important;
    -webkit-animation: fadein 400ms; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 400ms; /* Firefox < 16 */
    -ms-animation: fadein 400ms; /* Internet Explorer */
    -o-animation: fadein 400ms; /* Opera < 12.1 */
    animation: fadein 400ms;
  }
}

.fade {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  -o-animation: none;
  animation: none;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tax-list-detail-modal {
  min-width: 990px;
}

body,
html {
  font-family: 'Kanit', Arial, sans-serif;
  font-weight: 300;
  color: $text;
  background: #EEF1F5;
  -webkit-font-smoothing: antialiased;
}

body {
  background: transparent;
}

::-webkit-scrollbar-track {
  background-color: #fff8f9;
}

::-webkit-scrollbar {
  color: #f0f0f0;
  width: 6px;
  height: 10px;
  background-color: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background-color: #B83895;
  opacity: 0.9;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #672D91;
}

.text-red {
  color: #ef6666;
}

.btn {
  background: transparent;
  padding: 7px 20px;
  transition: 300ms all;
  font-weight: 300;
  border-radius: 5px !important;
  &:focus {
    box-shadow: none;
  }
}

.btn-primary {
  background: #036BB3;
  border-color: #036BB3;
  &:hover {
    background: darken(#036BB3, 10%);
    border-color: darken(#036BB3, 10%);
  }
  &:disabled {
    background: desaturate(#036BB3, 90%);
    border-color: desaturate(#036BB3, 90%);
  }
}

.btn-success {
  background: #036BB3;
  border-color: #036BB3;
  &:hover {
    background: darken(#036BB3, 10%);
    border-color: darken(#036BB3, 10%);
  }
  &:disabled {
    background: desaturate(#036BB3, 90%);
    border-color: desaturate(#036BB3, 90%);
  }
}

.btn-delete{
  color: #ffffff;
  background: #ef6666;
  border-color: #ef6666;
  &:hover {
    background: darken(#ef6666, 10%);
    border-color: darken(#ef6666, 10%);
  }
}

input {
  border-color: #DFE1E9;
  &:focus {
    box-shadow: none !important;
  }
}

::-webkit-input-placeholder {
  color: #DFE1E9 !important;
}

.header {
  margin-bottom: 20px;
}

// Customer by BNG

fa-icon.spinner-border {
  border: none !important;
  width: auto;
  height: auto;
  vertical-align: unset !important;
}

.header .row {
  width: unset;
}

.box-shadow {
  box-shadow: 0 0 1px 0 rgba(61, 119, 180, 0.12), 0 0.25rem 12px 0 rgba(91, 139, 199, 0.24);
}

.scrollable {
  overflow-x: auto;
  .table {
    white-space: nowrap;
  }
}

// custom button style
.btn-dv {
  background: #B83895;
  background-image: -webkit-linear-gradient(left, #B83895, #672D91);
  background-image: -moz-linear-gradient(left, #B83895, #672D91);
  background-image: -ms-linear-gradient(left, #B83895, #672D91);
  background-image: -o-linear-gradient(left, #B83895, #672D91);
  background-image: linear-gradient(to right, #B83895, #672D91);
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  border-radius: 50px !important;
  color: #FFFFFF;
  padding: 0.5rem;

  &:disabled {
    background: #838383;
  }

  &.btn-clean {
    background: #FFFFFF;
    border: solid #AF3694 1px;
    color: #AF3694;
  }

  &.btn-danger {
    background: red;
    border: none;
    -webkit-border-radius: 50px !important;
    -moz-border-radius: 50px !important;
    border-radius: 50px !important;
    color: #FFF;
  }

  &.btn-compact {
    padding-top: .2em !important;
    padding-bottom: .2em !important;
  }

  &.btn-wide {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  &.btn-wider {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
}

// floating label in input
:root {
  --input-label-padding-x: .75rem;
  --input-label-padding-y: .75rem;
}

.form-label-group {
  position: relative;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-label-padding-y) var(--input-label-padding-x);
}

.form-label-group > label {
  display: none;
}

.form-label-group input::-webkit-input-placeholder {
  color: var(--placeholder-color);
  opacity: var(--disabled-opacity);
}

.form-label-group input:-ms-input-placeholder {
  color: var(--placeholder-color);
  opacity: var(--disabled-opacity);
}

.form-label-group input::-ms-input-placeholder {
  color: var(--placeholder-color);
  opacity: var(--disabled-opacity);
}

.form-label-group input::-moz-placeholder {
  color: var(--placeholder-color);
  opacity: var(--disabled-opacity);
}

.form-label-group input::placeholder {
  color: var(--placeholder-color);
  opacity: var(--disabled-opacity);
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-label-padding-y) + var(--input-label-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-label-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-label-padding-y) / 3);
  padding-bottom: calc(var(--input-label-padding-y) / 3);
  font-size: 12px;
  color: #777;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

// custom primeng autocomplete style
.dv-autocomplete {
  .ui-inputtext {
    font-size: 1rem;
    color: #40474D;
  }

  .ui-placeholder {
    font-size: 1rem;
    color: #DADCE6;
  }

  .ui-autocomplete {
    width: 100% !important;
  }

  .ui-autocomplete ul.ui-inputtext {
    border-color: #DADCE6;
    width: 100% !important;
    height: 50px !important;  // TODO: fix this
  }

  .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container {
    padding: var(--input-label-padding-y) var(--input-label-padding-x);
  }

  .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled):hover,
  .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled).ui-state-focus {
    border: 1px solid #AF3694;
  }

  .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled).ui-state-focus {
    -webkit-box-shadow: 0 0 7px 0 rgba(175,54,148,0.50);
    -moz-box-shadow: 0 0 7px 0 rgba(175,54,148,0.50);
    box-shadow: 0 0 7px 0 rgba(175,54,148,0.50);
  }
}

// custom primeng autocomplete style
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token input {
  font-family: 'Kanit', Arial, sans-serif;
  font-weight: 300;
  font-size: 1em;
  line-height: 1;
}

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-token,
.dv-dropdown .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #B83895;
}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item {
  font-family: 'Kanit', Arial, sans-serif;
  font-weight: 300;
  line-height: 2;

  &.ui-state-highlight {
    background: #f2f2f2;
    color: black;
  }
}

.dropdown-pagination .ui-dropdown-panel {
  border-radius: 15px;

  .ui-dropdown-items li {
    &:first-child {
      border-radius: 15px 15px 0 0;
    }

    &:last-child {
      border-radius: 0 0 15px 15px;
    }
  }
}

// custom primeng drop down with label
.dropdown-label-group.is-invalid .dv-dropdown .p-dropdown {
  border-color: red;
}

.dropdown-label-group {
  position: relative;
  background: #FFF;
}

.dropdown-label-group .dv-dropdown .p-dropdown {
  background: transparent;
  padding: 0;
  width: 100% !important;
}

.dropdown-label-group .dv-dropdown label,
.dropdown-label-group > label {
  padding: var(--input-label-padding-y) var(--input-label-padding-x);
}

.dropdown-label-group > label {
  display: none;
}

.dropdown-label-group .p-element.p-dropdown-label.p-inputtext {
  padding-top: calc(var(--input-label-padding-y) + var(--input-label-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-label-padding-y) / 3);
  padding-left: var(--input-label-padding-y);
  padding-right: var(--input-label-padding-y);
  background: transparent;
}

.dropdown-label-group .dv-dropdown.p-inputwrapper ~ label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
  padding-top: calc(var(--input-label-padding-y) / 3);
  padding-bottom: calc(var(--input-label-padding-y) / 3);
  font-size: 12px;
  color: #777;
  // z-index: 1;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  pointer-events: none;
}

.dropdown-label-group .p-dropdown {
  // z-index: 2;
}

// custom primeng drop down style
.dv-dropdown {
  .ui-inputtext {
    font-size: 1rem;
    color: #40474D;
  }

  .ui-placeholder {
    font-size: 1rem;
    color: #DADCE6;
  }

  .p-dropdown {
    border-color: #DADCE6;
    //padding: .315rem .75rem;
    width: 100% !important;
  }

  .p-dropdown:not(.ui-state-disabled):hover,
  .p-dropdown:not(.ui-state-disabled).p-inputwrapper-focus {
    border: 1px solid #AF3694;
  }

  .p-dropdown:not(.ui-state-disabled).p-inputwrapper-focus {
    -webkit-box-shadow: 0 0 7px 0 rgba(175,54,148,0.50);
    -moz-box-shadow: 0 0 7px 0 rgba(175,54,148,0.50);
    box-shadow: 0 0 7px 0 rgba(175,54,148,0.50);
  }
}

.ui-dropdown label.ui-dropdown-label {
  font-family: 'Kanit', Arial, sans-serif;
  font-weight: 300;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.ui-dropdown-panel .ui-dropdown-item {
  font-family: 'Kanit', Arial, sans-serif;
  font-weight: 300 !important;
}

// Custome by BNG

.dropdown-pagination,
.dv-dropdown {
  .p-inputtext {
    color: #40474D;
  }

  .p-placeholder {
    color: #DADCE6;
  }

  .p-dropdown {
    border-color: #DADCE6;
  }

  .p-dropdown:not(.ui-state-disabled):hover,
  .p-dropdown:not(.ui-state-disabled).p-inputwrapper-focus {
    border: 1px solid #AF3694;
  }

  .p-dropdown:not(.ui-state-disabled).p-inputwrapper-focus  {
    -webkit-box-shadow: 0 0 7px 0 rgba(175,54,148,0.50);
    -moz-box-shadow: 0 0 7px 0 rgba(175,54,148,0.50);
    box-shadow: 0 0 7px 0 rgba(175,54,148,0.50);
  }

  .p-dropdown-items-wrapper .p-dropdown-items {
    padding: 0rem;
    margin: 0rem;
  }

  .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item {
    font-size: 14px;
  }

  .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #B83895;
  }
}

.dropdown-pagination .p-dropdown-panel {
  border-radius: 15px;
}

.dropdown-pagination p-dropdownitem.p-element:first-child .p-dropdown-item {
  border-radius: 15px 15px 0 0;
}

.dropdown-pagination p-dropdownitem.p-element:last-child .p-dropdown-item {
  border-radius: 0 0 15px 15px;
}

// Custome by BNG

// custom input style
.form-label-group input {
  caret-color: #672D91;
}

.form-label-group input:hover,
.form-label-group input:focus {
  border: 1px solid #AF3694 !important;
}

.form-label-group input:focus {
  -webkit-box-shadow: 0 0 7px 0 rgba(175,54,148,0.50) !important;
  -moz-box-shadow: 0 0 7px 0 rgba(175,54,148,0.50) !important;
  box-shadow: 0 0 7px 0 rgba(175,54,148,0.50) !important;
}

// custom checkbox style
.dv-checkbox {
  .p-chkbox .p-chkbox-box {
    border: 2px solid #B83895;

    &:not(.p-state-disabled):hover,
    &.p-state-default.p-state-focus {
      border-color: #AF3694;
    }

    &.p-state-active,
    &.p-state-active:not(.p-state-disabled):hover,
    &.p-state-active .p-chkbox-icon,
    &.p-state-active.p-state-focus {
      border-color: #B83895;
      background: #B83895;
    }

    .p-chkbox-icon {
      font-size: 14px;
    }
  }
}

// custom table style

.table th,
.table > tbody.no-head > tr:first-child td {
  background: #F0F2F6;
}

.table th,
.table td {
  vertical-align: middle;
  padding: .75rem;

}


p-table .p-datatable {
  font-family: 'Kanit', Arial, sans-serif;
  font-size: 14px;
}

p-table .p-datatable.p-component .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr th,
p-table .p-datatable.p-component .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: 1px solid #dee2e6;
  vertical-align: middle;
  text-align: center;
}

p-table .p-datatable.p-component .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr th {
  padding: .75em;
  background-color: #F0F2F6;
  border-bottom: 1px solid #dee2e6;
}

p-table .p-datatable.p-component .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td:nth-child(even) {
  background-color: white;
}

p-table .p-datatable.p-component .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td:nth-child(odd) {
  background-color: white;
}

p-table .p-datatable.p-component .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr th.p-state-highlight {
  background-color: #672D91;
}

// custom pagination style
.pagination {
  margin-bottom: 0;
}

.pagination .page-item {
  padding-left: .2em;
  padding-right: .2em;

  a {
    border: none;
    min-width: 2em;
    height: 2em;
    text-align: center;
    line-height: 1;
    border-radius: 25px;
    color: #AF3694;
  }

  a:focus {
    box-shadow: none;
  }

  &:first-child a,
  &:last-child a {
    min-width: 2em;
  }

  &.active a {
    color: #FFF;
    background: #ffd55a;
    background-image: -webkit-linear-gradient(left, #ffd55a, #faaa00);
    background-image: -moz-linear-gradient(left, #ffd55a, #faaa00);
    background-image: -ms-linear-gradient(left, #ffd55a, #faaa00);
    background-image: -o-linear-gradient(left, #ffd55a, #faaa00);
    background-image: linear-gradient(to right, #ffd55a, #faaa00);
  }

  &:first-child .page-link,
  &:last-child .page-link {
    border-radius: 25px;

    span {
      visibility: hidden;
    }

    span:before {
      font-weight: 900;
      visibility: visible;
    }
  }

  &:first-child,
  &:last-child {
    margin-right: .2em;
  }

  &:first-child .page-link span:before {
    content: '\f053';
  }

  &:last-child .page-link span:before {
    content: '\f054';
  }
}

// vertical divider
.v-divider {
  border-left: 1px solid #777;
  min-height: 2em !important;
  padding-left: 0;
  padding-right: 0;
  margin-left: 15px;
  margin-right: 15px;
}

.dv-pagination {
  flex: 1;
}

.dv-pagination ul.pagination .page-item {
  z-index: 0;
}

// page size style
.page-size {
  .p-inputtext {
    font-size: 14px;
    color: #AF3694;
    text-align: center;
  }

  .p-dropdown {
    border-color: #AF3694;
    border-radius: 25px;
    margin-left: 10px;
    margin-right: 10px;

    .p-dropdown-label {
      font-size: 14px;
      padding-top: .2em;
      padding-bottom: .2em;
      background: transparent;
    }

    .p-dropdown-trigger {
      color: #AF3694;
      background: transparent;
    }
  }

  .dv-dropdown:not(.ui-state-disabled):hover,
  .dv-dropdown:not(.ui-state-disabled).p-inputwrapper-focus {
    border: 1px solid #AF3694;
  }

  .dv-dropdown:not(.ui-state-disabled).ui-state-focus {
    -webkit-box-shadow: 0 0 7px 0 rgba(175,54,148,0.50);
    -moz-box-shadow: 0 0 7px 0 rgba(175,54,148,0.50);
    box-shadow: 0 0 7px 0 rgba(175,54,148,0.50);
  }
}

// custom primeng calendar style
body .ui-datepicker {
  border: none;
}

body .ui-datepicker table {
  font-size: .9em;
}

body .ui-datepicker table td > a,
body .ui-datepicker table td > span {
  border-radius: 25px;
}

body .ui-datepicker table td > a.ui-state-active {
  background-color: #672D91;
  font-weight: bold;
}

body .ui-datepicker table td.ui-datepicker-today a {
  font-weight: bold;

  &.ui-state-active {
    color: white;
  }
}

body .ui-datepicker:not(.ui-state-disabled) table td a:not(.ui-state-active):not(.ui-state-highlight):hover {
  background-color: rgba(103, 45, 145, 0.2);
}

// chip
.chip {
  padding: .2em 1em;
  border-radius: 20px;
  color: white;
  white-space: nowrap;

  &.success {
    background-color: lightseagreen;
  }

  &.danger {
    background-color: darkred;
  }
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #4BC0C0 !important;
}

body .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled):hover .p-inputswitch-slider {
  background: #4BC0C0 !important;
}

body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
  background: #4BC0C0 !important;
}

body .p-inputswitch .p-inputswitch-slider {
  //transition: background-color 0.2s;
  //border-radius: 30px;
  background: #FF6384 !important;
}

body .p-inputswitch:not(.p-state-disabled):hover .p-inputswitch-slider {
  background: #FF6384 !important;
}

body .p-inputswitch.p-inputswitch-focus .p-inputswitch-slider {
  background: #FF6384 !important;
}

body .p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff !important;
  height: 1em;
  width: 1em;
  left: 1px;
  border-radius: 50%;
  transition: 0.2s;
  top: 11px;
}

.p-input-switch-custom .p-inputswitch .p-inputswitch-slider {
  height: 20px;
  width: 40px;
}

.p-inputswitch-slider {
  top: auto;
}

p-toast{
  position:relative;
  z-index: 99999;
}

.modal-footer-alternative{
  text-align: right;
  border-top:1px solid #e9ecef;
  padding: 1rem;

  & > *{
    display: inline-block;
  }
}

.overflow-hidden{
  overflow: hidden;
}

.rc-anchor-invisible-text .rc-anchor-pt {
  transition: opacity 5s ease;
}
