@font-face {
    font-family: "Kanit";
    font-style: normal;
    src: url("./Kanit-Regular.ttf");
}
  
@font-face {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 700;
    src: url("./Kanit-Bold.ttf");
}
  
@font-face {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 500;
    src: url("./Kanit-Medium.ttf");
}