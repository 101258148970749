ng-select.ng-select {
  padding: 0;
  border-width: var(--bs-border-width);
  border-color: var(--bs-border-color);
  font-size: var(--font-size);
}

ng-select.ng-select.form-control {
  box-shadow: none;
}

ng-select.ng-select.form-control:focus {
  border: var(--bs-border-width) solid var(--bs-border-color);
}

ng-select.ng-select .ng-select-container {
  border: none;
  height: 100% !important;
  border-radius: var(--bs-border-radius);
}

ng-select.ng-select-single .ng-select-container,
ng-select.ng-select .ng-select-container:focus,
ng-select.ng-select .ng-select-container:hover {
  box-shadow: none !important;
}

.ng-select.ng-select-opened {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-bottom-right-radius: inherit !important;
  border-bottom-left-radius: inherit !important;
}

ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: unset !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  padding: unset !important;
  cursor: auto !important;
}

ng-select.ng-select.ng-select-hide-arrow .ng-select-container .ng-arrow-wrapper {
  display: none;
}

ng-select.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  opacity: var(--disabled-opacity);
  color: var(--placeholder-color);
}

ng-select.ng-select.ng-select-disabled,
ng-select.ng-select.ng-select-disabled .ng-select-container {
  background-color: var(--readonly-color) !important;
  opacity: var(--disabled-opacity);
}

ng-select.ng-select.ng-select-disabled input[type=text]:read-only {
  background-color: transparent !important;
}

ng-select.ng-select.custom-ng-select .ng-select-container .ng-value-container .ng-placeholder {
  opacity: 1;
  color: var(--placeholder-color);
}


ng-select.ng-select .ng-select-container .ng-value-container .ng-value .ng-value-label-invalid {
  border: 1px solid var(--bs-form-invalid-border-color);
  border-radius: 2px;
}

.ng-dropdown-panel.ng-select-bottom {
  box-shadow: none;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-top-color: unset !important;
}

ng-select.ng-select-text-nowrap .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: unset;
  overflow: unset;
  text-overflow: unset;
}

ng-select.ng-select.ng-select-vat-percentage .ng-select-container .ng-value-container {
  justify-content: center;
}